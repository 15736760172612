import React from 'react'

/**
* Diese Klasse enthält alle Teilnehmer der Dart-Weltmeisterschaft 2023 und die dazugehörigen Informationen.
* Die Teilnehmer werden mit einer ID in einem Array gespeichert.
* Der Name und die dazugehöprigen Informationen können über die entsprechenden Methoden erfragt werden.
*/

const participants = 
[

//Top 32 der PDC Order Of Merit
{ id: 1, name: "Michael Smith", nationality: "ENG", information: "As the title holder, the Englishman is once again one of the favourites in the battle for the world title this year. This will be his 13th appearance at a World Championship." },
{ id: 2, name: "Michael van Gerwen", nationality: "NED", information: "The Dutchman, who is considered by many to be the best darts player of all time, is also one of the favourites this year. Having already won three titles in 2014, 2017 and 2019, he will certainly do everything he can to add a fourth." },
{ id: 3, name: "Luke Humphries", nationality: "ENG", information: "After winning the 2023 World Grand Prix, the first major title of his career, the Englishman should not be underestimated. He also claimed his third major win of the year at the Players Championship Finals after also winning the Grand Slam of Darts. The 28-year-old won the PDC Youth World Championship in 2019 and is one of the sport's biggest talents." },
{ id: 4, name: "Peter Wright", nationality: "SCO", information: "The Scot will be taking part in the World Championships for the 15th time. As a former two-time winner and winner of the European Darts Championship 2023, he is once again one of the bigger favourites." },
{ id: 5, name: "Gerwyn Price", nationality: "WAL", information: "With his former World Championship title and his three previous Grand Slam victories, the Welshman is always one of the favourites for the title. His aim this year is certainly to make up for his quarter-final defeat last year." },
{ id: 6, name: "Nathan Aspinall", nationality: "ENG", information: "The 32-year-old is taking part in the World Championships for the sixth time this year. He won his second PDC Major title at the World Matchplay 2023. The Englishman never gives up and can always be trusted to achieve a good result, which he can confirm with two World Championship semi-finals. " },
{ id: 7, name: "Danny Noppert", nationality: "NED", information: "Things didn't look good for the Dutchman in his previous appearances at the World Championships and he always lost quite early on. This year, he is once again seeded for the tournament and in combination with his strong nerves, the 32-year-old has a lot to play for." },
{ id: 8, name: "Rob Cross", nationality: "ENG", information: "After winning the title on his debut in 2018, the Englishman did not achieve great results at the World Championship in the years that followed. Perhaps that will change this year in his seventh consecutive appearance." },
{ id: 9, name: "Jonny Clayton", nationality: "WAL", information: "After the Welshman's victory in the World Cup of Darts and his final appearance in the World Matchplay, the 49-year-old should definitely be on the list for a good result. The Welshman has been playing in the PDC since 2015 and is playing his eighth World Championship this year, where his best results so far have been two last 16 finishes in the last two years." },
{ id: 10, name: "Damon Heta", nationality: "AUS", information: "This year, the Australian is taking part in the World Championships for the fifth time in a row. In recent years, he has only made it to the third round at best, but on the European Tour he has shown that he can interact well with the crowd. Perhaps it will help him to play to his strengths at the World Championships." },
{ id: 11, name: "Dave Chisnall", nationality: "ENG", information: "Over the course of the year, the Englishman won three tournaments on the European Tour, proving once again that he is a player for the stage and the big tournaments. " },
{ id: 12, name: "Dirk van Duijvenbode", nationality: "NED", information: "Even though the Dutchman has struggled with injury problems this year, he should not be written off. He is coming back stronger and stronger and is always ready for a good game and a long run thanks to his high strike rate." },
{ id: 13, name: "James Wade", nationality: "ENG", information: "The left-hander has taken part in every World Championship since 2005 and is playing in the World Championship for the 20th time this year. The Englishman is always a force to be reckoned with, as he proved when he reached the final of the European Darts Championship, where he beat shooting star Van Veen, among others." },
{ id: 14, name: "Joe Cullen", nationality: "ENG", information: "The Englishman has taken part in the World Championship every year since 2011, and this year will be his 14th. His results in recent years have not been convincing, but the 34-year-old has made a name for himself from time to time. Let's see if he can build on his performances at the World Championships." },
{ id: 15, name: "Dimitri Van den Bergh", nationality: "BEL", information: "The two-time youth world champion is taking part in the World Championships for the ninth time this year. After reaching the semi-finals last year, he is likely to achieve a good result again this year if the Belgian can bring his performance to the board." },
{ id: 16, name: "Ross Smith", nationality: "ENG", information: "The Englishman won the title at the European Darts Championship 2022, the first major title of his career. He is taking part in the World Championship for the seventh time this year, although he has never made it past the third round. Perhaps he will manage to achieve a better result this year as a seeded player, which is to be expected from the 34-year-old." },
{ id: 17, name: "Chris Dobey", nationality: "ENG", information: "As the reigning Masters champion, the Englishman is one of the Major winners in 2023 and is therefore one of the favourites for the title. His best World Championship result was the quarter-finals last year, which he can perhaps build on." },
{ id: 18, name: "Stephen Bunting", nationality: "ENG", information: "The Englishman had a comparatively bad year in 2023 without a PDC title. Nevertheless, it is quite possible that he will regain his old strength at the World Championship and achieve a good result in his tenth World Championship." },
{ id: 19, name: "Ryan Searle", nationality: "ENG", information: "As one of the Pro Tour winners of 2023, the Englishman is by no means one of the underdogs at his sixth World Championship. Perhaps he will make it past the round of 16 this year, which is his best result to date." },
{ id: 20, name: "Andrew Gilding", nationality: "ENG", information: "The Englishman has already taken part in the World Championship four times. Following his victory at the UK Open 2023, he will enter the tournament as a seeded player for the first time this year." },
{ id: 21, name: "Gary Anderson", nationality: "SCO", information: "The 52-year-old is regarded by many as one of the most talented players. With his two World Championship titles in 2015 and 2016, the Scot is always one of the favourites, which he underlined by winning three Pro Tour tournaments in 2023." },
{ id: 22, name: "Gabriel Clemens", nationality: "GER", information: "The German number one will be taking part in his sixth World Championships this year, where his best result was the semi-finals last year. Can he build on that performance and run this year?" },
{ id: 23, name: "Josh Rock", nationality: "NIR", information: "With his victory at the Youth World Championship, the Northern Irishman qualified for the World Championship for the first time in 2022, where he reached the round of 16. The 22-year-old is one of the most talented young players. Let's see where it takes him this year." },
{ id: 24, name: "Krzysztof Ratajski", nationality: "POL", information: "The Pole and his doubles partner set a new world record for a doubles match at this year's World Cup of Darts, proving once again that the 46-year-old is someone for the high averages and the consistent games." },
{ id: 25, name: "Jose de Sousa", nationality: "POR", information: "The Portuguese has never made it past the fourth round in his last six World Championship appearances. The 49-year-old proved in his Grand Slam victory 2020 that he never gives up and that his game remains unpredictable." },
{ id: 26, name: "Martin Schindler", nationality: "GER", information: "The 27-year-old is playing in the World Championship for the fifth time. He is seeded for the second time. After winning a match for the first time last year, he may go further than the third round this year." },
{ id: 27, name: "Daryl Gurney", nationality: "NIR", information: "The Northern Irish number two is playing in the World Championship for the eleventh time this year. His best result so far has been reaching the quarter-finals twice, both times losing to the eventual winner." },
{ id: 28, name: "Brendan Dolan", nationality: "NIR", information: "And the next Northern Irishman follows immediately. The 50-year-old made a name for himself when he became the first player to throw a nine-darter in double-in double-out mode. Two nine-darters were also to his credit this year. What is he capable of at his sixteenth World Championship?" },
{ id: 29, name: "Raymond van Barneveld", nationality: "NED", information: "The five-time world champion will be taking part in his seventeenth World Championships this year. Due to his numerous World Championship participations and tournament victories, he is one of the legends in the sport of darts." },
{ id: 30, name: "Callan Rydz", nationality: "ENG", information: "The young Englishman will be taking part in the World Championship for the fifth time, having already competed six times at the Youth World Championship, where his best result was a semi-final in 2022. What will he show at this World Championship?" },
{ id: 31, name: "Kim Huybrechts", nationality: "BEL", information: "Even though the Belgian has not been able to win any major titles for some time, he will once again go into the World Championships as a seeded player. On his debut 12 years ago, he made it all the way to the quarter-finals, which is still his best result to date. Perhaps he will return to that level of performance this year." },
{ id: 32, name: "Madars Razma", nationality: "LAT", information: "The Latvian is one of the seeded players for the first time at his fifth World Championships. So far, he has only made it as far as the second round, but perhaps he will manage to top that this year." },


    
//Top 32 der PDC Pro Tour Order Of Merit
{id: 33, name: "Ricardo Pietreczko", nationality: "GER", information: "The 29-year-old (from Team MyDartCoach) is currently playing his second year on tour. With good results on the floor (including a semi-final) and strong games on the European Tour, where he won his first PDC title, he is now number 16 in the Pro Tour Order of Merit. He will make his debut at the World Championship."},
{id: 34, name: "Gian van Veen", nationality: "NED", information: "The young Dutchman is probably one of the best newcomers of the season. In his first year on the tour card, he played his way through to the semi-finals of the European Darts Championship. At the same time, he managed six wins on the Development Tour, which he finished in first place. He will make his debut at the 2024 World Championship."},
{id: 35, name: "Ryan Joyce", nationality: "ENG", information: "With a title at the Players Championship among others, the Englishman managed to qualify for his sixth World Championship as number three on the Pro Tour Order of Merit."},
{id: 36, name: "Luke Woodhouse", nationality: "ENG", information: "The 35-year-old played his way into two semi-finals and a final at the Players Championship in 2023, thus qualifying for his fifth World Championship."},
{id: 37, name: "Mike De Decker", nationality: "BEL", information: "In his last three World Championships, the Belgian never made it past the second round. He has already won titles on the Development Tour in the past and has already played five World Youth Championships in the PDC. What will the 28-year-old achieve in his fourth World Championship?"},
{id: 38, name: "Ian White", nationality: "ENG", information: "He was the man for the floor tournaments and won several titles there, but the successes have been lacking for some time now. His best result at a World Championship so far was the round of 16. Perhaps he will make it beyond that at his twelfth World Championships."},
{id: 39, name: "Jim Williams", nationality: "WAL", information: "In 2021, the Welshman won the Challenge Tour and thus secured his tour card for 2022 and 2023. In order to qualify for his third World Championship, the 39-year-old played his way into a Players Championship semi-final and once even into the final."},
{id: 40, name: "Mario Vandenbogaerde", nationality: "BEL", information: "In 2022, the Belgian secured his tour card through qualifying school. After almost two years on the tour, he will make his PDC World Championship debut after playing a BDO World Championship and reaching the semi-finals."},
{id: 41, name: "Radek Szaganski", nationality: "POL", information: "The Pole surprisingly wins one of the Players Championship tournaments in 2023. He has been active on the tour for two years and will make his debut at the 2024 World Championship."},
{id: 42, name: "Richard Veenstra", nationality: "NED", information: "In his first year with a Tourcard, the Dutchman manages to qualify for his first World Championship. Over the course of the year, the 42-year-old made it to the quarter-finals in two Players Championships. Let's see if his experience from five BDO World Championships will help him in his debut at the PDC World Championship."},
{id: 43, name: "Steve Beaton", nationality: "ENG", information: "Perhaps he is THE veteran of the sport of darts. The Englishman hasn't missed a World Championship since 2002 and this year will be his 23rd appearance at the PDC World Championship. With a quarter-final on the European Tour and a place in the Top 32 at the UK Open, he has once again attracted attention this year."},
{id: 44, name: "Keane Barry", nationality: "IRL", information: "After six Youth World Championships, the Irishman qualifies for the PDC World Championship for the fifth time. However, he never got further than the second round."},
{id: 45, name: "Simon Whitlock", nationality: "AUS", information: "At the age of 54, the Australian will be taking part in a PDC World Championship for the sixteenth time. His results have not been the best in recent years, but he is known for playing unusual finishes at important moments. Perhaps that will help him. "},
{id: 46, name: "Kevin Doets", nationality: "NED", information: "The young Dutchman has won several titles on the Challenge Tour and Development Tour in recent years, thus securing his tour card. After five Youth World Championships, he will also be making his debut."},
{id: 47, name: "Matt Campbell", nationality: "CAN", information: "The Canadian won his tour card via the 2021 Challenge Tour and will take part in the World Championships for the fifth time this year. So far, he has always lost his opening match."},
{id: 48, name: "Connor Scutt", nationality: "ENG", information: "The 27-year-old has reached one Players Championship semi-final and one final. He will also be making his World Championship debut in his second year."},
{id: 49, name: "Martin Lukeman", nationality: "ENG", information: "Among other things, the Englishman is playing his way into a Players Championship final in order to qualify for his second World Championship at the end of the year, after making his debut last year in which he reached the second round."},
{id: 50, name: "Mickey Mansell", nationality: "NIR", information: "Since his debut in 2011, the Northern Irishman has now played his way to the World Cup for the eighth time. However, the 50-year-old has never made it past the second round."},
{id: 51, name: "Dylan Slevin", nationality: "IRL", information: "The Irishman is playing in the World Championship for the first time after playing his first Q-School at the beginning of the year, where he immediately earned his tour card. He has celebrated two victories on the Development Tour in the past and qualified twice for the Youth World Championship."},
{id: 52, name: "Steve Lennon", nationality: "IRL", information: "The 30-year-old played several European Tour tournaments with semi-good results, among others, but in the end just managed to qualify for his sixth World Championship via the Pro Tour rankings."},
{id: 53, name: "William O’Connor", nationality: "IRL", information: "The Irishman attracted attention last year with a 9-darter and high averages and managed to qualify for the World Series of Darts Finals. The 37-year-old is also qualifying for his seventh World Championship, where he has never made it past the third round in the past."},
{id: 54, name: "Scott Williams", nationality: "ENG", information: "The Englishman made his UK Open debut this year and played himself into a European Tour semi-final and managed to qualify for his second World Championship with good results."},
{id: 55, name: "Christian Kist", nationality: "NED", information: "The 37-year-old does not have a tour card for 2023, but has played in several Players Championship tournaments as a successor from the Challenge Tour due to his great results there. At senior level he reached a semi-final and a final this year, among other good results. After three BDO World Championships, which he won once, the Dutchman qualified for his fifth PDC World Championship."},
{id: 56, name: "Ricky Evans", nationality: "ENG", information: "The Englishman played a 9-darter this year and reached the second round in several European Tour tournaments. The 43-year-old also made it into the last 64 of the UK Open. He was ranked in the top 32 in the world for a long time, but is now entering the tournament as an unseeded player."},
{id: 57, name: "Cameron Menzies", nationality: "SCO", information: "The Scot only made his debut at the World Championships last year and has now qualified for the second time. The 34-year-old has only had his tour card since 2022 and played his way into the quarter-finals of a Players Championship tournament."},
{id: 58, name: "Lee Evans", nationality: "ENG", information: "The Englishman has only had his tour card since 2023 and can make his debut at the World Championships this year."},
{id: 59, name: "Ritchie Edhouse", nationality: "ENG", information: "The 40-year-old has been playing with the pros on a tour card since 2017. With two semi-finals at the Players Championship tournaments, the Englishman qualified for his fourth World Championship."},
{id: 60, name: "Jermaine Wattimena", nationality: "NED", information: "The Dutchman was part of the world's top 32 for a long time, but this year he goes into the tournament as an unseeded player. The 35-year-old never made it past the third round at the World Championships."},
{id: 61, name: "Jules van Dongen", nationality: "USA", information: "Born in the Netherlands, the US-American will make his World Championship debut this year after two years on the tour. Last year he played the WDF World Championship and also qualified for the World Cup of Darts 2023."},
{id: 62, name: "Jamie Hughes", nationality: "ENG", information: "The 37-year-old is trying to get past the second round at his fifth World Championships, something he has not yet managed to do. By reaching a Players Championship final, he was able to qualify for the World Championship, among other things."},
{id: 63, name: "Niels Zonneveld", nationality: "NED", information: "The young Dutchman has won two titles on the Development Tour in the past and qualified for a total of five World Youth Championships. This year, the 25-year-old will be playing in his third World Championships."},
{id: 64, name: "Keegan Brown", nationality: "ENG", information: "The Englishman lost his tour card last year after a poor World Championship, but won it again straight away in January 2023. In recent years, the 31-year-old has been the tournament winner at a Players Championship. He has now qualified for his eighth World Championship."},

 //Die 32 internationalen Qualifikanten
{id: 65, name: "Marko Kantele", nationality: "FIN", information: "The 55-year-old was the first Finn to qualify for a PDC World Championship. On the PDC Nordic Baltic Tour, the Finn won four titles and finished first in the rankings, which brings him to the Ally Pally for the sixth time."},
{id: 66, name: "Jeffrey de Graaf", nationality: "SWE", information: "The Swede made his debut at the 2017 World Championships, after which he got his tour card, but lost it again after two years. In 2023, the 33-year-old won two tournaments on the PDC Nordic-Baltic Tour and played his way up to second place in the rankings. This enabled him to qualify for the World Championship for the third time."},
{id: 67, name: "Darren Penhall", nationality: "AUS", information: "In 2020, the Australian won the tour card via the Q-School. He won seven titles on the Australian DPA Tourcard and won this tour in his favour, giving the 51-year-old his World Championship debut."},
{id: 68, name: "Lourence Ilagan", nationality: "PHI", information: "The Phillipine made his World Cup debut in 2012 and has since proved himself to be the best dart player in the Philippines. He won two titles on the 2023 Asian Tour. He qualified for his seventh World Cup by finishing first in the Asian Tour rankings."},
{id: 69, name: "Tomoya Goto", nationality: "JPN", information: "The young Japanese player represented his country twice at the World Cup of Darts. Now comes his World Championship debut. He won two titles on the Asian Tour in 2023 and finished second on the Asian Tour."},
{id: 70, name: "Paolo Nebrida", nationality: "PHI", information: "The Phillipine was at the Ally Pally for the first time for the 2023 World Chanpionship. The following year, he won his first title on the Asian Tour. He finished the season in third place in the Asian Tour rankings and is therefore allowed to play in his second World Championship."},
{id: 71, name: "Reynaldo Rivera", nationality: "PHI", information: "As number 4 on the Asian Tour, the Phillipine had to play a play-off match, which he won despite match darts against him. This entitles him to make his PDC World Championship debut this year."},
{id: 72, name: "Luke Littler", nationality: "ENG", information: "He is regarded by many as one of England's greatest talents. The Englishman made his debut at the UK Open at the beginning of the year, shortly after his sixteenth birthday. He was crowned World Youth Champion three weeks before the World Championships. With a total of five titles on the Development Tour, the 16-year-old is playing his way towards a qualifying place for the World Championships. This will be his debut."},
{id: 73, name: "Wessel Nijman", nationality: "NED", information: "After a two-and-a-half-year absence, the Dutchman returned to the Development Tour in 2023 and managed to climb to third place in the rankings in the process. As van Veen qualified for the World Championships via the ProTour and retains his tour card, Nijman is allowed to make his World Championship debut and will receive a tour card for 2024 and 2025."},
{id: 74, name: "Xiaochen Zong", nationality: "CHN", information: "In 2017, the 25-year-old won the Chinese qualifier to make his world championship debut. Six years later, he won the PDC China Premiere League and played his way into the Ally Pally for the second time."},
{id: 75, name: "Alex Spellman", nationality: "USA", information: "The US-American has won several titles on the WDF Tour. In 2023, he was allowed to make his debut at a World Series event thanks to a strong season 22 on the CDC Tour. With three titles on the CDC Tour in 2023, he topped the rankings and thus qualified for his World Championship debut."},
{id: 76, name: "Stowe Buntz", nationality: "USA", information: "The 44-year-old won three titles on the CDC Tour and made it to second place in the rankings. As the winner of the Continental Cup, he qualified for his debut in the Grand Slam of Darts. The US-American will also be making his World Championship debut."},
{id: 77, name: "David Cameron", nationality: "CAN", information: "The Canadian made his World Series of Darts debut in 2017. In 2022, he won the World Seniors Masters, which was held for the first time. With a title on the CDC Tour, he was able to play his way up to third place in the rankings and thus secure his second World Championship."},
{id: 78, name: "Simon Adams", nationality: "RSA", information: "In September, the 63-year-old won the African qualifier and thus qualified for his World Championship debut. He had already performed well in the African Darts Group and was therefore seeded second for the qualifier."},
{id: 79, name: "Haruki Muramatsu", nationality: "JPN", information: "The 46-year-old made his World Championship debut at the 2010 World Cup, and only a short time later his World Cup of Darts debut. At the beginning of March this year, the Japanese started playing the Asian Tour again. Through this tour he qualified for the PDC Asian Championship, which he won. This qualified him for the Grand Slam of Darts and his fourth World Championship."},
{id: 80, name: "Sandro Eric Sosing", nationality: "PHI", information: "The Philipine is a regular on the Asian Tour and made it into the top 20 of the rankings this year, which qualified him for the PDC Asian Championship. There he played himself into the final. This qualified him for his World Championship debut this year."},
{id: 81, name: "Man Lok Leung", nationality: "HKG", information: "In 2021, the 23-year-old made his debut at the World Cup of Darts. Over the course of the year, the Hong Kong player won three Asian Tour titles and finished fifth in the rankings. He will make his World Championship debut this year."},
{id: 82, name: "Ben Robb", nationality: "NZL", information: "The New Zealander has taken part in several World Series of Darts tournaments. He also made his debut at the World Cup of Darts in 2022. He has four wins to his name on the DPNZ Tour and ultimately won the DPNZ Qualifier. This will be his fourth World Championship. "},
{id: 83, name: "Norman Madhoo", nationality: "GUY", information: "The Guyanese already made his debut at a PDC World Championship in 2004 and will take part for the fourth time this year. He managed to qualify via the PDC Latin America and Caribbean Tour, which he finished first in the rankings."},
{id: 84, name: "Fallon Sherrock", nationality: "ENG", information: "The Englishwoman had to win the UK Women's Qualifier in 2019 to make her World Championship debut. Four years have passed since then and she is now one of the best women in the world. In 2022, she won the Women's World Matchplay, which was held for the first time. The 29-year-old will be taking part in the World Championship for the third time. Her best result to date was reaching the third round on her debut."},
{id: 85, name: "Mikuru Suzuki", nationality: "JPN", information: "Due to the cancellation of Beau Greaves, the Japanese athlete moves up into the field of participants for the World Championships. The 41-year-old has won two BDO World Championship titles in the past and made her PDC World Championship debut in 2020, qualifying twice for the Grand Slam of Darts. In 2023, she won three titles in the Women's Series and reached the final of the Women's World Matchplay. She is now ending the year by taking part in her second World Championship."},
{id: 86, name: "Haupai Puha", nationality: "NZL", information: "The New Zealander has been invited to several World Series of Darts tournaments. He made his World Championship debut in 2022 and the 38-year-old qualified for his second World Championship by winning the DPA Oceanic Masters."},
{id: 87, name: "Bhav Patel", nationality: "IND", information: "Due to Jiwa's suspension, Patel moves up as a runner-up in the India Qualifier and will make his World Championship debut this year."},
{id: 88, name: "Berry van Peer", nationality: "NED", information: "Before the Dutchman was plagued by dartitis, he won the Tour Card for the first time in 2016. The 27-year-old won four titles on this year's Challenge Tour and played his way to the top of the rankings. As a result, he qualified for his World Championship debut and will have a tour card again from January."},
{id: 89, name: "Owen Bates", nationality: "ENG", information: "The Englishman has only been active in the PDC since 2022. He was unable to earn a tour card at Q-School 2023, but attracted attention with two Players Championship Round of 16 matches, among other things. On the Challenge Tour, the 20-year-old was able to play his way up to second place and will therefore be able to make his World Championship debut."},
{id: 90, name: "Dragutin Horvat", nationality: "GER", information: "The 47-year-old has not missed a PDC Super League since 2013. He made his World Championship debut in 2017 but was eliminated in round one. Over the years, he has repeatedly managed to qualify for European Tour tournaments. After seven years, the German won the Super League once again and secured his second trip to the Ally Pally."},
{id: 91, name: "Thibault Tricole", nationality: "FRA", information: "He is the first of his nation to make it to a PDC World Championship. Only last year the Frenchman played himself into the final of the WDF World Championship and only a short time later he made his debut on the PDC European Tour. The 34-year-old also made his debut at the World Cup of Darts later this year. He also attracted attention with good results on both the Challenge Tour and the ProTour."},
{id: 92, name: "Krzysztof Kciuk", nationality: "POL", information: "In 2010, the Pole made his debut at a PDC World Championship. It was not until ten years later that he won his tour card for the first time, lost it after two years and was able to regain it in 2022. In 2023, the 43-year-old was allowed to make his debut at the World Series of Darts. In an all-Polish final at the East Europe Qualifier, he beat Sebastian Bialecki to secure his second World Championship participation."},
{id: 93, name: "Florian Hempel", nationality: "GER", information: "Although the German had a difficult year in the PDC, he still managed to qualify for the World Darts Championship through the PDPA Qualifier. There we will see if his recent improved form will be enough to secure his tour card for next year. He already took part in the World Championship in 2022 & 2023."},
{id: 94, name: "Darren Webster", nationality: "ENG", information: "The 55-year-old Englishman qualified for his eleventh World Championship and will be back at the Ally Pally for the first time since 2020. He qualified via the PDPA Qualifier, which was his last chance to take part this year."},
{id: 95, name: "Rusty-Jake Rodriguez", nationality: "AUT", information: "The 23-year old Austrian was able to secure one of the fourth starting places at the World Championships via the PDPA Qualifier. He will be competing at the Ally Pally for the second time and will be looking to claim his first victory there."},
{id: 96, name: "Boris Krčmar", nationality: "HRV", information: "The 44-year-old secured the last of the four places in the PDPA Qualifier for the World Darts Championship, winning the final match against Pascal Rupprecht. Overall, this is his fifth PDC World Championship participation. He has already taken part in the Ally Pally the last 3 years in a row."}


]





/**
 * Diese Methode liefert den Namen des Spielers zu der übergebenen ID zurück
 * @param {*} id ID des Spielers, zu dem der Name erfragt werden soll
 * @returns Name des Spielers als String, zu der dazugehörigen ID - wenn kein Name vorhanden return undefined
 */
export function getParticipantName(id)
{
    if(participants[id-1])
    {
        //Optional: Falls Spieler unter Top 32 wird die Setzlistenposition mit angegeben
        if(id <= 32)
        {
            return participants[id-1].name + " (" + id + ")";
        }

        if(participants[id-1].name == "Mensur Suljovic")
        {
            return "Mensur Suljović";
        }

        return participants[id-1].name;
        
    }
    return undefined;
}

/**
 * Diese Methode liefert die Informationen des Spielers zu der übergebenen ID zurück
 * @param {*} id ID des Spielers, zu dem die Informationen erfragt werden soll
 * @returns Informationen des Spielers als String, zu der dazugehörigen ID - wenn keine Informationen vorhanden return undefined
 */
export function getParticipantInformation(id)
{
    if(participants[id-1])
    {
        return participants[id-1].information;
    }
    return undefined;
}

/**
 * Diese Methode liefert die Nationalität des Spielers zu der übergebenen ID zurück
 * @param {*} id ID des Spielers, zu dem die Nationalität erfragt werden soll
 * @returns Nationalität des Spielers als String, zu der dazugehörigen ID - wenn keine Nationalität vorhanden return undefined
 */
 export function getParticipantNationality(id)
 {
     if(participants[id-1])
     {
         return participants[id-1].nationality;
     }
     return undefined;
 }

 export function getParticipantNameForPDF(id)
{
    if(participants[id-1])
    {
        //Optional: Falls Spieler unter Top 32 wird die Setzlistenposition mit angegeben
        if(id <= 32)
        {
            return participants[id-1].name + " (" + id + ")";
        }

            if(participants[id-1].name == "Boris Krčmar")
            {
                return "Boris Krcmar";
            }
            else if(participants[id-1].name == "José Justicia")
            {
                return "Jose Justicia";
            }

        return participants[id-1].name;
        
    }
    return "";
}